import { AnimatePresence, motion } from "framer-motion";
import Hamburger from "hamburger-react";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useClickAway } from "react-use";
import logo from "../../../assets/image/logo_laboground_white.svg";
import Button from "../../../modules/common/components/Button";
import Flex from "../../../modules/common/components/Flex";
import LanguageSelector from "../../../modules/common/components/LanguageSelector";
import Text from "../../../modules/common/components/Text";
import { ALT_TEXT, APP_DOMAIN } from "../../../utils/constants";
import theme from "../../theme";
import { SCREENS } from "../utils/router";

interface BurgerMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const MOBILE_TOP_BAR_HEIGHT = 64;

function BurgerMenu({ isOpen, onClose }: BurgerMenuProps) {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickAway(wrapperRef, () => {
    onClose();
  });

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex: 100000000,
      }}
    >
      <div ref={wrapperRef}>
        <AnimatePresence>
          {isOpen && (
            <div>
              <Flex flexDirection="column" gap={theme.spacing[20]}>
                <Flex
                  height={MOBILE_TOP_BAR_HEIGHT}
                  alignItems="center"
                  position="relative"
                  padding={`${theme.spacing[8]}px ${theme.spacing[24]}px`}
                  backgroundColor={theme.color.black}
                >
                  <Link to={SCREENS.HOME}>
                    <img
                      src={logo}
                      alt={ALT_TEXT}
                      style={{ height: 18, objectFit: "contain" }}
                    />
                  </Link>
                  <Flex flex={1} justifyContent="flex-end">
                    <Hamburger
                      color="white"
                      toggled={isOpen}
                      toggle={onClose}
                    />
                  </Flex>
                </Flex>
                <motion.nav
                  initial={{ top: 0, opacity: 0 }}
                  animate={{ top: MOBILE_TOP_BAR_HEIGHT, opacity: 1 }}
                  exit={{ top: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{
                    position: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    right: 0,
                    top: MOBILE_TOP_BAR_HEIGHT,
                    bottom: 0,
                    width: "100vw",
                    backgroundColor: theme.color.background50,
                    paddingTop: theme.spacing[16],
                    paddingBottom: theme.spacing[16],
                  }}
                >
                  <Sidebar
                    style={{ width: "100%", flex: 1 }}
                    backgroundColor="transparent"
                    rootStyles={{
                      borderRight: "none",
                    }}
                  >
                    <Menu
                      menuItemStyles={{
                        root: {
                          backgroundColor: "transparent",
                        },
                        button: {
                          [`&.ps-active`]: {
                            fontWeight: "bold",
                            backgroundColor: "rgba(255,255,255,0.2)",
                          },

                          [`&:hover`]: {
                            backgroundColor: "rgba(255,255,255,0.2)",
                          },

                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      {/* TODO: uncomment this after release */}
                      {/* <MenuItem
                      // active={pathname.includes(SCREENS.DASHBOARD)}
                      // component={<Link to={SCREENS.DASHBOARD} />}
                      >
                        <Text typography="16B">{t("navigation.pricing")}</Text>
                      </MenuItem>
                      <MenuItem
                      // active={pathname.includes(SCREENS.DASHBOARD)}
                      // component={<Link to={SCREENS.DASHBOARD} />}
                      >
                        <Text typography="16B">{t("navigation.contact")}</Text>
                      </MenuItem> */}
                      <LanguageSelector />
                    </Menu>
                  </Sidebar>

                  <Flex
                    alignItems="center"
                    gap={theme.spacing[24]}
                    padding={`${theme.spacing[24]}px ${theme.spacing[20]}px`}
                  >
                    <Flex flex={1}>
                      <a href={APP_DOMAIN} target="_blank">
                        <Button
                          fullWidth
                          text={t("common.preRegistration")}
                          isGradient
                          fontColor="white"
                          size="md"
                        />
                      </a>
                    </Flex>
                    {/* TODO: uncomment this after release */}
                    {/* <Flex flex={1}>
                      <Button fullWidth text={t("navigation.login")} />
                    </Flex>

                    <Flex flex={1}>
                      <Button
                        fullWidth
                        text={t("navigation.freeTrial")}
                        isGradient
                        fontColor="white"
                        size="md"
                      />
                    </Flex> */}
                  </Flex>
                </motion.nav>
              </Flex>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default BurgerMenu;
