import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import { useClickAway } from "react-use";
import Flex from "./Flex";
import IconButton from "./IconButton";
import closeIcon from "../../../assets/icon/close.svg";
import theme from "../../../lib/theme";

interface DropdownItemProps {
  body: React.ReactNode;
  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
  width?: string | number;
  showCloseButton?: boolean;
  onClose: () => void;
}

function DropdownItem({
  body,
  top = `calc(100% + ${theme.spacing[12]}px)`,
  left,
  right,
  bottom,
  width,
  showCloseButton = true,
  onClose,
}: DropdownItemProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickAway(dropdownRef, () => {
    onClose();
  });

  return (
    <div ref={dropdownRef}>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          style={{
            position: "absolute",
            zIndex: 10000000,
            top,
            left,
            right,
            bottom,
            width,
            padding: theme.spacing[8],
            backgroundColor: theme.color.white,
            display: "flex",
            flexDirection: "column",
            borderRadius: 12,
            border: `1px solid ${theme.color.background50}`,
            gap: theme.spacing[4],
          }}
        >
          {showCloseButton && (
            <Flex justifyContent="flex-end">
              <IconButton
                variant="contained"
                bgColor="transparent"
                icon={closeIcon}
                iconSize={16}
                size={32}
                onClick={onClose}
              />
            </Flex>
          )}
          {body}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default DropdownItem;
