import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import LayoutProvider from "../../../modules/common/providers/LayoutProvider";

const HomeContainer = lazy(
  () => import("../../../modules/home/container/HomeContainer")
);

export const SCREENS = {
  HOME: "/",
};

export const router = createBrowserRouter([
  {
    element: <LayoutProvider />,
    children: [
      {
        path: SCREENS.HOME,
        element: <HomeContainer />,
      },
    ],
  },
]);
