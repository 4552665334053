import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/image/logo_laboground_white.svg";
import Flex from "../../../modules/common/components/Flex";
import Text from "../../../modules/common/components/Text";
import { useScreenWidthContext } from "../../../modules/common/providers/ScreenWidthProvider";
import { ALT_TEXT } from "../../../utils/constants";
import theme from "../../theme";
import { SCREENS } from "../utils/router";

function Footer() {
  const { isMobile } = useScreenWidthContext();

  return (
    <Flex
      flexDirection={isMobile ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      padding={`${theme.spacing[32]}px ${theme.spacing[isMobile ? 20 : 80]}px`}
      backgroundColor={theme.color.black}
      gap={theme.spacing[24]}
    >
      <Link to={SCREENS.HOME}>
        <img
          src={logo}
          alt={ALT_TEXT}
          style={{ height: isMobile ? 18 : 20, objectFit: "contain" }}
        />
      </Link>
      <Text typography="16" color="white">
        @ 2024 OPEPE. All rights reserved.
      </Text>
      {/* <ul
        style={{
          display: "flex",
          gap: theme.spacing[24],
          alignItems: "center",
        }}
      >
        <li>
          <Text typography="16" color="white">
            Terms
          </Text>
        </li>
        <li>
          <Text typography="16" color="white">
            Privacy
          </Text>
        </li>
        <li>
          <Text typography="16" color="white">
            Support
          </Text>
        </li>
      </ul> */}
    </Flex>
  );
}

export default Footer;
