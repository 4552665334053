import React from "react";
import RouteContainer from "./lib/route/container/RouterContainer";

function App() {
  return (
    <>
      <RouteContainer />
    </>
  );
}

export default App;
