import React, { CSSProperties } from "react";
import { Color } from "theme";
import Flex from "./Flex";
import theme from "../../../lib/theme";

interface IconButtonProps {
  icon: string;
  variant?: "contained" | "outline";
  size?: number;
  iconSize?: number;
  round?: boolean;
  fullWidth?: boolean;
  bgColor?: keyof Color | "transparent" | string;
  borderColor?: keyof Color;
  borderRadius?: number;
  disabled?: boolean;
  isGradient?: boolean;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
  onClick?: () => void;
}

function IconButton({
  icon,
  variant = "contained",
  size = 40,
  iconSize = 24,
  round = true,
  fullWidth = false,
  bgColor = "tableHeader",
  borderColor = "primary",
  borderRadius = 0,
  disabled = false,
  isGradient = false,
  style = {},
  iconStyle = {},
  onClick,
}: IconButtonProps) {
  const iconStyles: CSSProperties = {
    width: fullWidth ? size : iconSize,
    height: fullWidth ? size : iconSize,
    borderRadius: fullWidth ? size / 2 : 0,
    objectFit: "cover",
    ...iconStyle,
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
      borderRadius={round ? size / 2 : borderRadius}
      cursor={disabled ? "auto" : "pointer"}
      outline={
        variant === "outline" ? `1px solid ${theme.color[borderColor]}` : "none"
      }
      background={
        disabled
          ? theme.color.tableHeader
          : !isGradient
          ? bgColor === "transparent" || !(bgColor in theme.color)
            ? bgColor
            : variant !== "contained"
            ? theme.color.white
            : theme.color[bgColor as keyof Color]
          : "linear-gradient(to right, #f68822, #fa5247)"
      }
      style={style}
      onClick={() => {
        if (!disabled) {
          onClick && onClick();
        }
      }}
    >
      {/* TODO: Change this logic when default profile icon is added */}
      {!!icon && <img src={icon} style={iconStyles} />}
    </Flex>
  );
}

export default IconButton;
