import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownItem from "./DropdownItem";
import Flex from "./Flex";
import Text from "./Text";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../providers/ScreenWidthProvider";

const ICONS: { [key: string]: string } = {
  ko: "🇰🇷",
  en: "🇬🇧",
};

function LanguageSelector() {
  const { i18n, t } = useTranslation();

  const { isMobile } = useScreenWidthContext();

  const languageOptions = useMemo(() => {
    return Object.keys(i18n.store.data).map((key) => {
      return {
        key,
        language: t("language." + key),
        icon: ICONS[key],
      };
    });
  }, [i18n.store.data, i18n.resolvedLanguage]);

  const dropdownProp = useMemo(() => {
    const defaultProp = {
      top: `calc(100% + ${theme.spacing[24]}px)`,
      right: 0,
    };

    if (isMobile) {
      return {
        ...defaultProp,
        left: 0,
      };
    } else {
      return {
        ...defaultProp,
      };
    }
  }, [isMobile]);

  const currentLanguage = i18n.resolvedLanguage;

  const [show, setShow] = useState(false);

  function onLanguageSelect(key: string) {
    i18n.changeLanguage(key);
    setShow(false);
  }

  return (
    <div
      style={{
        position: "relative",
        padding: `${isMobile ? theme.spacing[12] : 0}px ${
          isMobile ? theme.spacing[20] : 0
        }px`,
      }}
    >
      <Flex
        alignItems="center"
        justifyContent={isMobile ? "flex-start" : "center"}
        cursor="pointer"
        gap={theme.spacing[8]}
        onClick={() => setShow((prev) => !prev)}
      >
        <FontAwesomeIcon icon={faGlobe} size="lg" />
        {isMobile && <Text typography="16B">{t("language.language")}</Text>}
      </Flex>
      {show && (
        <DropdownItem
          showCloseButton={false}
          {...dropdownProp}
          body={
            <Flex
              flexDirection="column"
              padding={`${theme.spacing[4]}px ${theme.spacing[16]}px`}
              gap={theme.spacing[12]}
              cursor="pointer"
            >
              {languageOptions.map((option) => (
                <Flex
                  key={option.language}
                  alignItems="center"
                  justifyContent={isMobile ? "center" : "flex-start"}
                  gap={theme.spacing[4]}
                  onClick={() => onLanguageSelect(option.key)}
                >
                  <Text>{option.icon}</Text>
                  <Text
                    typography={currentLanguage === option?.key ? "16B" : "16"}
                    color={theme.color.black2}
                  >
                    {option.language}
                  </Text>
                </Flex>
              ))}
            </Flex>
          }
          onClose={() => setShow(false)}
        />
      )}
    </div>
  );
}

export default LanguageSelector;
