import React, { memo } from "react";
import { Color } from "theme";
import Flex from "./Flex";
import theme from "../../../lib/theme";
import { ALT_TEXT } from "../../../utils/constants";

export interface ButtonProps {
  fullWidth?: boolean;
  variant?: "contained" | "outline" | "text";
  text?: string;
  size?: "lg" | "md" | "sm" | "xs";
  round?: boolean;
  bgColor?: keyof Color | "transparent" | string;
  leftIcon?: string;
  rightIcon?: string;
  disabled?: boolean;
  borderColor?: keyof Color;
  fontColor?: keyof Color;
  isGradient?: boolean;
  cursor?: "pointer" | "default";
  onClick?: () => void;
}

function Button({
  fullWidth = false,
  variant = "contained",
  text,
  size = "md",
  round = true,
  bgColor = "primary",
  leftIcon,
  rightIcon,
  disabled = false,
  borderColor = "primary",
  fontColor = "black",
  isGradient = false,
  cursor,
  onClick,
}: ButtonProps) {
  const padding =
    size === "xs"
      ? `${theme.spacing[8]}px ${theme.spacing[8]}px`
      : size === "sm"
      ? `${theme.spacing[8]}px ${theme.spacing[16]}px`
      : size === "md"
      ? `${theme.spacing[12]}px ${theme.spacing[24]}px`
      : `${theme.spacing[12]}px ${theme.spacing[16]}px`;

  const iconSize =
    size === "xs" ? 12 : size === "sm" ? 16 : size === "md" ? 20 : 24;

  const gap = theme.spacing[4];

  const font =
    size === "xs"
      ? theme.typography["12B"]
      : size === "sm"
      ? theme.typography["14B"]
      : size === "md"
      ? theme.typography["16B"]
      : theme.typography["20B"];

  return (
    <Flex
      cursor={cursor ? cursor : disabled ? "auto" : "pointer"}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      background={
        disabled
          ? theme.color.tableHeader
          : !isGradient
          ? bgColor === "transparent" || !(bgColor in theme.color)
            ? bgColor
            : variant !== "contained"
            ? theme.color.white
            : theme.color[bgColor as keyof Color]
          : "linear-gradient(to right, #f68822, #fa5247)"
      }
      width={fullWidth ? "100%" : "fit-content"}
      borderRadius={round ? 5 : 0}
      padding={padding}
      gap={gap}
      outline={
        variant === "outline" ? `1px solid ${theme.color[borderColor]}` : "none"
      }
      onClick={() => {
        if (disabled) return;

        onClick && onClick();
      }}
    >
      {!!leftIcon && (
        <img
          src={leftIcon}
          style={{ width: iconSize, height: iconSize }}
          alt={ALT_TEXT}
        />
      )}
      {!!text && (
        <div
          style={{
            ...font,
            color: disabled ? theme.color.gray2 : theme.color[fontColor],
          }}
        >
          {text}
        </div>
      )}
      {!!rightIcon && (
        <img
          src={rightIcon}
          style={{ width: iconSize, height: iconSize }}
          alt={ALT_TEXT}
        />
      )}
    </Flex>
  );
}

export default memo(Button);
