import { setContext } from "@apollo/client/link/context";

const authLink = setContext(async (_, { headers }) => {
  try {
    return {
      headers: {
        ...headers,
      },
    };
  } catch (err) {
    console.error("Error in token: ", err);
    if (err === "No current user") {
      return {
        headers,
      };
    }
    throw err;
  }
});

export default authLink;
