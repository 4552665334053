import { ReactChildrenProp } from "global";
import React, { CSSProperties, memo } from "react";
import { Color, Typography } from "theme";
import theme from "../../../lib/theme";
import { useScreenWidthContext } from "../providers/ScreenWidthProvider";

export interface TextProps extends ReactChildrenProp {
  typography?: keyof Typography;
  color?: keyof Color | string;
  mobileTypography?: keyof Typography;
}

function Text({
  children,
  typography = "16",
  mobileTypography: _mobileTypography,
  color = "black",
  ...rest
}: TextProps & CSSProperties) {
  const { isMobile } = useScreenWidthContext();

  function generateMobileTypography(): keyof Typography {
    let mobileTypography: keyof Typography = typography;

    if (typography?.includes("40")) {
      mobileTypography = "32B";
    } else if (typography?.includes("32")) {
      mobileTypography = "24B";
    } else if (typography?.includes("24")) {
      mobileTypography = "20B";
    } else {
      mobileTypography = typography;
    }

    return mobileTypography;
  }

  let mobileTypography = _mobileTypography || generateMobileTypography();

  return (
    <div
      style={{
        color: !(color in theme.color)
          ? color
          : theme.color[color as keyof Color],
        ...(isMobile
          ? theme.typography[mobileTypography]
          : theme.typography[typography]),
        ...rest,
      }}
    >
      {children}
    </div>
  );
}

export default memo(Text);
