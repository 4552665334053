import { ReactChildrenProp } from "global";
import React, { CSSProperties, ComponentPropsWithRef, memo } from "react";

interface FlexProps {
  className?: string;
  onClick?: () => void;
}

function Flex({
  children,
  className = undefined,
  onClick,
  ...rest
}: FlexProps &
  ReactChildrenProp &
  CSSProperties &
  ComponentPropsWithRef<"div">) {
  return (
    <div
      className={className}
      style={{ display: "flex", ...rest }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default memo(Flex);
