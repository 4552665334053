import Hamburger from "hamburger-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import logo from "../../../assets/image/logo_laboground_white.svg";
import Button from "../../../modules/common/components/Button";
import Flex from "../../../modules/common/components/Flex";
import LanguageSelector from "../../../modules/common/components/LanguageSelector";
import Text from "../../../modules/common/components/Text";
import { useScreenWidthContext } from "../../../modules/common/providers/ScreenWidthProvider";
import { ALT_TEXT, APP_DOMAIN } from "../../../utils/constants";
import theme from "../../theme";
import { SCREENS } from "../utils/router";

function NavigationBar() {
  const { isMobile } = useScreenWidthContext();

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <nav
        style={{
          width: "100%",
          backgroundColor: theme.color.black,
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          padding: `${theme.spacing[isMobile ? 8 : 16]}px ${
            theme.spacing[isMobile ? 24 : 40]
          }px`,
          color: theme.color.white,
        }}
      >
        <Flex alignItems="center" position="relative" height="100%">
          <Link to={SCREENS.HOME}>
            <img
              src={logo}
              alt={ALT_TEXT}
              style={{ height: isMobile ? 18 : 20, objectFit: "contain" }}
            />
          </Link>
          <Flex
            alignItems="center"
            flex={1}
            justifyContent="flex-end"
            gap={theme.spacing[20]}
          >
            {!isMobile ? (
              <>
                <ul
                  style={{
                    display: "flex",
                    gap: theme.spacing[20],
                    alignItems: "center",
                  }}
                >
                  <li>
                    <a href={APP_DOMAIN} target="_blank">
                      <Button
                        isGradient
                        fontColor="white"
                        text={t("common.preRegistration")}
                      />
                    </a>
                  </li>

                  {/* TODO: uncomment this after release */}
                  {/* <li>
                    <Text typography="16B" color={theme.color.white}>
                      {t("navigation.pricing")}
                    </Text>
                  </li>
                  <li>
                    <Text typography="16B" color={theme.color.white}>
                      {t("navigation.contact")}
                    </Text>
                  </li>
                  <li>
                    <Button
                      text={t("navigation.freeTrial")}
                      isGradient
                      fontColor="white"
                      size="md"
                    />
                  </li>
                  <li>
                    <Button text={t("navigation.login")} />
                  </li> */}
                </ul>
                <LanguageSelector />
              </>
            ) : (
              <Hamburger toggled={isOpen} toggle={setIsOpen} />
            )}
          </Flex>
        </Flex>
      </nav>
      {isOpen && (
        <BurgerMenu isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
}

export default NavigationBar;
