import { ApolloProvider } from "@apollo/client";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import App from "./App";
import "./index.css";
import client from "./lib/apollo";
import "./lib/i18n";
import Loader from "./modules/common/components/Loader";
import ScreenWidthProvider from "./modules/common/providers/ScreenWidthProvider";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ScreenWidthProvider>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <Suspense fallback={<Loader fullScreen />}>
            <App />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar
              closeOnClick
              theme="dark"
            />
          </Suspense>
        </ApolloProvider>
      </HelmetProvider>
    </ScreenWidthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
