import { ApolloClient, InMemoryCache } from "@apollo/client";
import cache, { setDefaultCache } from "./cache";
import link from "./link";

const client = new ApolloClient({
  link,
  cache,
});

client.onClearStore(async () => {
  setDefaultCache(cache);
});

export default client;
