import React, { createContext, useCallback, useContext, useState } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "../../../lib/route/components/Footer";
import NavigationBar from "../../../lib/route/components/NavigationBar";

interface LayoutContextValue {
  showTopBar: boolean;
  showFooter: boolean;
}

interface LayoutContextHandler {
  toggleShowTopbar: (showTopBar: boolean) => void;
  toggleShowFooter: (showFooter: boolean) => void;
}

const initialValue: LayoutContextValue = {
  showTopBar: true,
  showFooter: true,
};

const LayoutContext = createContext<LayoutContextValue & LayoutContextHandler>({
  ...initialValue,
  toggleShowTopbar: () => {},
  toggleShowFooter: () => {},
});

function LayoutProvider() {
  const [state, setState] = useState<LayoutContextValue>({ ...initialValue });

  const toggleShowTopbar = useCallback(() => {
    setState((prev) => ({
      ...prev,
      showTopBar: !prev.showTopBar,
    }));
  }, []);

  const toggleShowFooter = useCallback(() => {
    setState((prev) => ({
      ...prev,
      showFooter: !prev.showFooter,
    }));
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        ...state,
        toggleShowTopbar,
        toggleShowFooter,
      }}
    >
      <ScrollRestoration />
      {state.showTopBar && <NavigationBar />}
      <Outlet />
      {state.showFooter && <Footer />}
    </LayoutContext.Provider>
  );
}

export default LayoutProvider;

export function useLayoutContext() {
  return useContext(LayoutContext);
}
